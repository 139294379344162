import React, { useEffect, useState, Fragment } from "react";
import API from "../../../../utils/apiCalling";
import { Link } from "react-router-dom";
import { config } from "../../../../utils/apiUrl";
import { get } from "lodash";
import moment from "moment";
import Select from "react-select";
import LiveMatrixPlusModal from "../../../../components/common/liveMatrixPlusModal";
import TrendingWarrent from "./components/TrendingWarrent";
import { getLiveMatrix } from "../../../../utils/WarrantIssue.api"
import SelectComp from "../../../WarrantTools/LiveMatrix/SelectComp"
import ReactTooltip from "react-tooltip";
import { GetWarrantAnnouncements } from "../../../../utils/warrantAnnouncements";

const WarrantTool = (props) => {
  const api = new API();
  const ric_id = JSON.parse(window.localStorage.getItem("ric")) !== null ? JSON.parse(window.localStorage.getItem("ric"))[0] : ""
  const [quickHeadlines, setQuickHeadlines] = useState([]);
  const [topTradedStock, setTopTradedStock] = useState([]);
  const [liveIndexHSI, setLiveIndexHSI] = useState({});
  const [liveIndexHSISec, setLiveIndexHSISec] = useState("00");
  const [liveIndexHTI, setLiveIndexHTI] = useState({});
  const [liveIndexFBM, setLiveIndexFBM] = useState([]);
  const [liveIndexFBMKey, setLiveIndexFBMKey] = useState("");
  const [symbolList, setSymbolList] = useState([]);
  const [liveMatrixData, setLiveMatrixData] = useState([]);
  const [liveMatrixSelected, setLiveMatrixSelected] = useState("");
  const [liveMatrixSelectedName, setLiveMatrixSelectedName] = useState("");
  const [symbol, setSymbol] = useState([])
  const [ric, setRic] = useState(ric_id ? ric_id : "CWCI_t97.KL")
  const [viewMoreLiveMatrix, setViewMoreLiveMatrix] = useState("");
  const [viewMoreExpiryWarrarnt, setViewMoreExpiryWarrarnt] = useState(false);
  const [viewMoreDynamicStaticPrice, setViewMoreDynamicStaticPrice] = useState(false);
  const [viewMoreSoldout, setviewMoreSoldout] = useState(false)
  const [expiryDates, setExpiryDates] = useState([]);
  const [underlying, setUnderlying] = useState([]);
  const [soldOut, setSoldOut] = useState([]);
  const [issuers, setIssures] = useState([]);
  const [openLive, setOpenLive] = useState(false);
  const [underlyingSelected, setUnderlyingSelected] = useState("all");
  const [callPutSelected, setCallPutSelected] = useState("all");
  const [expirySelected, setExpirySelected] = useState("all");
  const [resultName, setResultName] = useState()
  const [load, setLoad] = useState(false)
  const [result, setResult] = useState([])
  const [symbolListNew, setSymbolListNew] = useState([])
  const [hotList, sethotList] = useState({ index: [], stock: [] })
  const [recogniaurl, setrecogniaurl] = useState("")
  const [liveindexes, setliveindexes] = useState({})
  const [staticData, setstaticData] = useState(null)
  const [localRic, setLocalRic] = useState(JSON.parse(window.localStorage.getItem("ric")) === null ? [] : JSON.parse(window.localStorage.getItem("ric")))

  // const { setOpen,setData } = props;
  const { setOpen } = props;

  const callPut = [
    { value: "all", label: "Call/Put" },
    { value: "call", label: "Call" },
    { value: "put", label: "Put" },
  ];

  useEffect(() => {
    getWarrantTermsSelect()
    getStaticData()
  }, [])

  const getStaticData = async () => {
    try {
      const res = await GetWarrantAnnouncements()
      if (res && res.data) {
        setstaticData(res.data)
      }
    } catch (error) {
      console.log(error)
    }
  }

  const getRic = (e) => {
    setRic(e.split(",")[0])
    var temp = [e.split(",")[0], ...localRic]
    setLocalRic(temp)
    window.localStorage.setItem("ric", JSON.stringify(temp));
    setResultName("")
    setResult([])
    setLoad(false)
  }

  const getWarrantTermsSelect = async () => {
    let LiveIndexSymbolResult = await api.get(config.homeLiveIndexFuturesSymbol, true, { type: "u", qid: Date.now() })
    let liveIndexSymbolRes = get(LiveIndexSymbolResult, "symbols", [])
    var liveIndexSymbolData = liveIndexSymbolRes.map(
      ({ name, symbol, ric: value }) => {
        if (symbol == 'KLSE') {
          return {
            label: `${name} ${"("}${'FBMKLCI'}${")"}`, value, symbol
          }
        } else if (symbol == 'S&P500') {
          return {
            label: `${name} ${"("}${'SP500'}${")"}`, value, symbol
          }
        } else if (symbol == 'N225') {
          return {
            label: `Nikkei Stock Average (Nikkei 225) Index`, value, symbol
          }
        } else {
          return { label: `${name} ${"("}${symbol}${")"}`, value, symbol }
        }
      }
      //  { label: `${name} ${"("}${symbol}${")"}`, value, symbol }
    );
    liveIndexSymbolData = liveIndexSymbolData.filter((item) => {
      return item.symbol != 'N225'
    })
    let selectedOneName = liveIndexSymbolRes && liveIndexSymbolRes[0] && liveIndexSymbolRes[0].name
    let selectedOneCode = liveIndexSymbolRes && liveIndexSymbolRes[0] && liveIndexSymbolRes[0].symbol
    let selectedOneRic = liveIndexSymbolRes && liveIndexSymbolRes[0] && liveIndexSymbolRes[0].symbol
    let tempSelectedName = selectedOneName + " (" + selectedOneCode + ") "
    var finalList = [
      { label: "All underlying", value: "all", symbol: "all" },
      { value: "Indices", label: "Indices", symbol: "Indices" },
      { value: "HSI,HSTECH,A50", label: "HK indices", symbol: "HSI,HSTECH,A50" },
      { value: "SPX,DJIA,NDX,S&P500", label: "US indices", symbol: "SPX,DJIA,NDX,S&P500" },
      { value: "N225", label: "Nikkei Stock Average (Nikkei 225) Index", symbol: "N225" },
      { value: "hkstocks", label: "Hong Kong stocks", symbol: "hkstocks" },
      ...liveIndexSymbolData]
    setSymbolListNew(finalList)
  }

  useEffect(() => getLiveMatrixData(ric, 0), [ric])

  function getLiveMatrixData(ric, mode) {
    getLiveMatrix(ric, mode)
      .then((res) => {
        setResult(res.data.livematrix)
        setResultName(res.data.ric_data)
        setSymbol(res.data.symbols)
        setLoad(true)
      })
  }
  useEffect(() => {
    getHomeQuickHeadlines();
    getHomeTopTradedStock();
    getHomeWarrantSearch();
    getHomeLiveIndexHSI();
    getHomeLiveIndexHTI();
    getHomeLiveIndexFBM();
    const timer = setInterval(() => getHomeLiveIndexFBM(), [5000]);
    getHomeLiveIndexSymbol();
    getHomeJson();
    getRecognia();
    return () => clearInterval(timer)
  }, []);

  useEffect(() => {
    getHomeLiveMatrixSelection();
  }, [liveMatrixSelected]);

  function findMidPoint(lmdata, lmprice, iscompress, isfiveday, type) {
    var mp = {
      price: -1,
      diff: -1,
      index: -1
    };
    for (var i = 0; i < lmdata.length; i++) {
      if (iscompress && !(i % 2 == 0)) {
        var ubid;
        if (isfiveday && !iscompress) {
          ubid = Object.keys(lmdata[i])[0];
        } else {
          ubid = lmdata[i][(type == 'PUT') ? "underlying_ask" : "underlying_bid"];
        }
        var diff = Math.abs(lmprice - ubid);
        if (mp.index === -1 || mp.diff > diff) {
          mp.diff = diff;
        }
        continue;
      }
      var ubid;
      if (isfiveday && !iscompress) {
        ubid = Object.keys(lmdata[i])[0];
      } else {
        ubid = lmdata[i][(type == 'PUT') ? "underlying_ask" : "underlying_bid"];
      }
      var diff = Math.abs(lmprice - ubid);
      if (mp.index === -1 || mp.diff > diff) {
        mp.diff = diff;
        mp.price = ubid;
        mp.index = i;
      }
    }
    return mp;
  }

  function getLiveMatrixData(ric, mode) {
    getLiveMatrix(ric, mode)
      .then((res) => {
        var livematrix = res.data.livematrix;
        var ric_data = res.data.ric_data;
        var ubidask = (ric_data?.type == 'PUT') ? "underlying_ask" : "underlying_bid";
        var fdata = []
        if (livematrix && livematrix.length > 4) {
          var midpoint;
          var sdata = []
          var ldata = []
          if (livematrix[0].is_compressed == "true") {
            midpoint = findMidPoint(livematrix, ric_data.underlying_bid_lm, true, false, ric_data.type);
            var hrow, trow;
            var scount = 1, lcount = 1;
            var startIndex, endIndex;
            if (midpoint.index > livematrix.length - 3) {
              startIndex = midpoint.index - 4;
              endIndex = midpoint.index + 1;
            } else if (midpoint.index < 2) {
              startIndex = 0;
              endIndex = 5;
            } else {
              startIndex = midpoint.index - 2;
              endIndex = midpoint.index + 3;
            }
            for (var i = startIndex; i < endIndex + 1; i++) {
              hrow = livematrix[i];
              trow = livematrix[i + 1]

              if (!isNaN(hrow[ubidask])) {
                hrow[ubidask] = Number(hrow[ubidask]);
              }

              if (hrow.head_tail_id == trow.head_tail_id) {
                if (hrow[ubidask] == midpoint.price) {
                  hrow["classList"] = "midpoint";
                  trow["classList"] = "midpoint";
                } else if (hrow[ubidask] < midpoint.price) {
                  hrow["classList"] = "srow srow" + scount;
                  trow["classList"] = "srow srow" + scount;
                  scount++;
                } else if (hrow[ubidask] > midpoint.price) {
                  hrow["classList"] = "lrow lrow" + scount;
                  trow["classList"] = "lrow lrow" + scount;
                  lcount++;
                }
                ldata.push(hrow)
                ldata.push(trow)
                i++;
              }
            }
            if (ric_data.BID == 0.04) {
              for (var i = lcount; i >= lcount - 8; i--) {
                sdata.push("lrow lrow" + i)
              }
            } else {
              for (var i = lcount; i >= lcount - 8; i--) {
                sdata.push("lrow lrow" + i)
              }
              for (var i = 1; i <= 8; i++) {
                sdata.push("srow srow" + i)
              }
            }
            ldata = ldata.filter(el => {
              return sdata.includes(el.classList) || el.classList === "midpoint"
            })
            var isOdd = true;
            var cnt = 1;
            ldata.forEach((el, i) => {
              var cl = el.classList
              if (cnt > 2) {
                cnt = 1;
                isOdd = !isOdd;
              }
              cnt++;
              if (isOdd) {
                cl += " bgcolor-08";
                el["classList"] = cl
              }
              fdata.push(el)
            })
          } else {
            midpoint = findMidPoint(livematrix, ric_data.underlying_bid_lm, false, false, ric_data.type);
            var livematrixindex = 0;
            var livematrixlimit = 9;
            livematrixindex = midpoint.index;
            if (livematrixindex == 0) {
              livematrixindex = Math.ceil(livematrix.length / 2);
            }
            var startIndex, endIndex;
            if (midpoint.index == livematrix.length - 1) {
              startIndex = midpoint.index - 4;
              endIndex = midpoint.index;
            } else if (midpoint.index < 2) {
              startIndex = 0;
              endIndex = 4;
            } else {
              startIndex = midpoint.index - 2;
              endIndex = midpoint.index + 2;
            }
            for (var i = startIndex; i < endIndex + 1; i++) {
              var livematrixdata = livematrix[i];
              var isMidPoint = (livematrixdata.underlying_bid == midpoint.price) ? true : false;
              var trclass = isMidPoint ? "midpoint" : ""
              livematrixdata["classList"] = trclass
              ldata.push(livematrixdata)
            }
            var isOdd = true;
            var cnt = 1;
            ldata.forEach((el, i) => {
              var cl = el.classList
              if (cnt > 2) {
                cnt = 1;
                isOdd = !isOdd;
              }
              cnt++;
              if (isOdd) {
                cl += " bgcolor-08";
                el["classList"] = cl
              }
              fdata.push(el)
            })
          }
        }
        if (res.data.ric_data?.BID == 0.005) {
          fdata = []
        }
        setResult(fdata)
        setResultName(res.data.ric_data)
        setSymbol(res.data.symbols)
        setliveindexes({ midpoint, startIndex, endIndex })
        setLoad(true)
      })
  }

  const getHomeQuickHeadlines = async () => {
    let homeQuickHeadlinesResult = await api.get(
      config.homeQuickHeadlines,
      true,
      { m: "dwnews", qid: Date.now() }
    );
    let soldOutArr = get(homeQuickHeadlinesResult, "soldout", []);
    setQuickHeadlines(homeQuickHeadlinesResult);
    setSoldOut(soldOutArr);
    // setData(homeQuickHeadlinesResult)
  };
  const getHomeJson = async () => {
    let homeJsonResult = await api.get(
      config.homeQuickHeadlines,
      true,
      { m: "dwtool", qid: Date.now() }
    );
    let hotlist = get(homeJsonResult, "hotlist", { index: [], stock: [] })
    sethotList(hotlist)

  };
  const getRecognia = async () => {
    let recogniaResult = await api.get(
      config.recognia,
      true,
      { url: 1, sym: ".HSI", exch: "INDEX", qid: Date.now() }
    );
    let rurl = recogniaResult.url
    setrecogniaurl(rurl)
  };

  const redirectToWarrantSearch = () => {
    return `/tools/warrantsearch?underlying=${encodeURIComponent(underlyingSelected)}&type=${callPutSelected}&expiry=${expirySelected}`;
  };

  const getHomeTopTradedStock = async () => {
    let homeTopTradedStockResult = await api.get(
      config.homeTopTradedStock,
      true,
      { type: "toptradedstock", qid: Date.now() }
    );
    let topList = get(homeTopTradedStockResult, "topList", []);
    setTopTradedStock(topList);
  };

  const getHomeLiveIndexHSI = async () => {
    let LiveIndexHSIResult = await api.get(config.homeLiveIndexFutures, true, {
      ric: "HSI",
    });
    LiveIndexHSIResult.chng = String(LiveIndexHSIResult.chng)
    LiveIndexHSIResult.pchng = String(LiveIndexHSIResult.pchng)
    setLiveIndexHSI(LiveIndexHSIResult);
    // let getSec = new Date().getSeconds() < 10 ? "0" + new Date().getSeconds() : new Date().getSeconds()
    let getSec = '';
    let seconds = new Date().getSeconds() < 5 ? '57' : new Date().getSeconds() + '';
    let s1 = '';
    let s2 = '';
    if (seconds.length == 2) {
      s1 = seconds.charAt(0) + '';
      if (seconds.charAt(1) < 5) {
        s2 = '0';
      } else {
        s2 = '5';
      }
    } else {
      s1 = '0';
      if (seconds.charAt(0) < 5) {
        s2 = '0';
      } else {
        s2 = '5';
      }
    }
    getSec = s1 + s2;
    setLiveIndexHSISec(getSec)
  };

  const getHomeLiveIndexHTI = async () => {
    let LiveIndexHTIResult = await api.get(config.homeLiveIndexFutures, true, {
      ric: "HTI",
    });
    LiveIndexHTIResult.chng = String(LiveIndexHTIResult.chng)
    LiveIndexHTIResult.pchng = String(LiveIndexHTIResult.pchng)
    setLiveIndexHTI(LiveIndexHTIResult);
  };

  const getHomeLiveIndexFBM = async () => {
    let time = Date.now();
    let LiveIndexFBMResult = await api.get(config.homeLiveIndexFutures, true, {
      _t: time,
    });
    LiveIndexFBMResult.pchng = String(LiveIndexFBMResult.pchng)
    LiveIndexFBMResult.chng = String(LiveIndexFBMResult.chng)
    setLiveIndexFBM(LiveIndexFBMResult);
    setLiveIndexFBMKey(LiveIndexFBMResult && LiveIndexFBMResult.keys && LiveIndexFBMResult.keys[0])

  };
  const getHomeLiveIndexSymbol = async () => {
    let LiveIndexSymbolResult = await api.get(
      config.homeLiveIndexFuturesSymbol,
      true,
      { qid: Date.now() }
    );
    let liveIndexSymbolRes = get(LiveIndexSymbolResult, "symbols", []);
    const liveIndexSymbolData = liveIndexSymbolRes.map(
      ({ name, security_code, ric: value }) => ({
        label: `${name} ${"("}${security_code}${")"}`,
        value,
        security_code,
      })
    );
    let selectedOneName =
      liveIndexSymbolRes && liveIndexSymbolRes[0] && liveIndexSymbolRes[0].name;
    let selectedOneCode =
      liveIndexSymbolRes &&
      liveIndexSymbolRes[0] &&
      liveIndexSymbolRes[0].security_code;
    let selectedOneRic =
      liveIndexSymbolRes && liveIndexSymbolRes[0] && liveIndexSymbolRes[0].ric;
    let tempSelectedName = selectedOneName + " (" + selectedOneCode + ") ";
    setSymbolList(liveIndexSymbolData);
    setLiveMatrixSelectedName(tempSelectedName);
    setViewMoreLiveMatrix(selectedOneCode);
    setLiveMatrixSelected(selectedOneRic);
  };

  const getHomeLiveMatrixSelection = async () => {
    let LiveMatrixResult = await api.get(
      `${config.homeLiveMatrix}?ric=${liveMatrixSelected}&mode=0`
    );
    let liveMatrixRes = get(LiveMatrixResult, "livematrix", []);
    setLiveMatrixData(LiveMatrixResult);
  };

  const getHomeWarrantSearch = async () => {
    let homeWarrantSearchResult = await api.get(
      config.homeWarrantSearch,
      true,
      { init: 1, qid: Date.now() }
    );
    let expiryDatesRes = get(homeWarrantSearchResult, "expiryDates", []);
    let underlyingRes = get(homeWarrantSearchResult, "underlyings", []);
    let issuersRes = get(homeWarrantSearchResult, "issuers", []);
    const tempUnderLying = underlyingRes.map(
      ({ underlying_name: label, underlying_ticker: value }) => ({
        label,
        value,
      })
    );
    const tempExpiryDates = expiryDatesRes.map(
      ({ num: label, num: value }) => ({ label, value })
    );
    setExpiryDates([{ label: "All expiry", value: "all" }, ...tempExpiryDates]);
    setUnderlying(tempUnderLying);
    setIssures(issuersRes);
  };

  const refreshLiveIndex = () => {
    getHomeLiveIndexHSI();
    getHomeLiveIndexFBM();
    getHomeLiveIndexHTI();
  };

  // const colourStyles = {
  //   option: (styles, { data, isDisabled, isFocused, isSelected }) => {
  //     return {
  //       ...styles,
  //       backgroundColor: isFocused ? "#f5f5f5" : null,
  //       color: "#333333",
  //     };
  //   },
  //   placeholder:(styles,{})=>{
  //     return {
  //     ...styles,
  //     fontSize:16
  //     }
  //   }
  // };
  const colourStyles = {
    option: (provided, state) => {
      return {
        // backgroundColor: state.isFocused ? "#007fff !important" : ["1", "2"].includes(state.innerProps.id.split("-")[4]) ? "#fff !important" : null,
        backgroundColor: state.isFocused
          ? "#007fff"
          : ["1", "2", "3", "4", '5'].includes(state.innerProps.id.split("-")[4])
            ? "#FECC0A"
            : null,
        color: "#333",
        color: state.isFocused ? "#ffffff" : null,
        fontSize: "14px",
        textAlign: "center",
        padding: "3px 14px 3px 5.6px",
        cursor: "pointer",
        borderTop: state.isFocused ? "1px solid #003eff" : null,
        borderBottom: state.isFocused ? "1px solid #003eff" : null,
      };
    },
  };

  const colourStyles2 = {
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      return {
        ...styles,
        backgroundColor: isFocused ? "#f5f5f5" : null,
        color: "#333",
        textAlign: "left",
        fontSize: "14px",
        padding: "3px 20px",
        fontWeight: "400",
        lineHeight: "1.42857143",
        whiteSpace: "nowrap",
        cursor: "pointer",
      };
    },
    placeholder: (styles, { }) => {
      return {
        ...styles,
        color: "#333",
        fontSize: 16,
        marginLeft: -8
      }
    }
  };

  return (
    <>
      <div id="dwtool" className="tabcontent">
        <p className="nav-dtxt show md-up-none" data-value="dwtool">
          Stay ahead of the market with our warrant tools.
        </p>
        <div className="frame-lgmd visible-sm">
          <div className="row">
            <div className="col-sm-6">
              <TrendingWarrent data={hotList} />
              <div className="holder-indexfuture xs-view">
                <div id="indexfuture" className="section">
                  <div
                    id="LiveIndexFutures"
                    style={{
                      marginTop: "-50px",
                      position: "absolute",
                    }}
                  />
                  <div className="page-header">
                    <h1 className="small">Live index futures</h1>
                  </div>
                  <table className="table table-striped table-noHeaderborder">
                    <thead>
                      <tr>
                        <th>Index</th>
                        <th />
                        <th>As at</th>
                        <th>Contract</th>
                        <th>Last</th>
                        <th>Change</th>
                        <th>Change%</th>
                      </tr>
                    </thead>
                    {liveIndexHSI.code && liveIndexHTI.code && <tbody>
                      <tr className="row_HSI">
                        <td>
                          <Link to={"/tools/underlying/HSIc1"}>HSI*</Link>
                        </td>
                        <td rowSpan={2} style={{ verticalAlign: "middle" }}>
                          <img
                            className="refresh_index"
                            style={{
                              cursor: "pointer",
                              marginLeft: 5,
                              width: 22,
                            }}
                            onClick={() => refreshLiveIndex()}
                            src="/img/bullet/refresh.png"
                          />
                        </td>
                        <td
                          rowSpan={2}
                          style={{ verticalAlign: "middle" }}
                          className="ticktime_HSI"
                        >
                          {/* {moment(new Date(liveIndexHSI.stime)).format("hh:mm:ss A")} */}
                          {moment(new Date(liveIndexHSI.stime)).format("hh:mm:ss")}
                          {/* :{liveIndexHSISec} */}
                          {new Date(liveIndexHSI.stime).getHours() > 11 ? " PM" : " AM"}
                        </td>
                        <td className="col_month">{liveIndexHSI.month}</td>
                        <td className="col_last">{liveIndexHSI.last.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</td>
                        <td className={`col_chng val ${liveIndexHSI.chng > 0 ? "up" : liveIndexHSI.chng < 0 ? "down" : ""}`}>
                          {liveIndexHSI.chng == 0 ? 0 : liveIndexHSI.chng.includes("-") ? liveIndexHSI.chng : "+" + liveIndexHSI.chng}
                        </td>
                        <td className={`col_pchng val ${Number(liveIndexHSI.pchng.replace('%', '')) > 0 ? "up" : Number(liveIndexHSI.pchng.replace('%', '')) < 0 ? "down" : ""}`}>
                          {liveIndexHSI.pchng == 0 ? 0 : liveIndexHSI.pchng.includes("-") ? liveIndexHSI.pchng.replace('%', '') : "+" + liveIndexHSI.pchng.replace('%', '')}
                        </td>
                      </tr>
                      <tr className="row_HTI">
                        <td>
                          <Link to={"/tools/underlying/HHTIc1"}>HSTECH*</Link>
                        </td>

                        <td className="col_month">{liveIndexHTI.month}</td>
                        <td className="col_last">{liveIndexHTI.last.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</td>
                        <td className={`col_chng val ${liveIndexHTI.chng > 0 ? "up" : liveIndexHTI.chng < 0 ? "down" : ""}`}>
                          {liveIndexHTI.chng == 0 ? 0 : liveIndexHTI.chng.includes("-") ? liveIndexHTI.chng : "+" + liveIndexHTI.chng}
                        </td>
                        <td className={`col_pchng val ${Number(liveIndexHTI.pchng.replace('%', '')) > 0 ? "up" : Number(liveIndexHTI.pchng.replace('%', '')) < 0 ? "down" : ""}`}>
                          {liveIndexHTI.pchng == 0 ? 0 : liveIndexHTI.pchng.includes("-") ? liveIndexHTI.pchng.replace('%', '') : "+" + liveIndexHTI.pchng.replace('%', '')}
                        </td>
                      </tr>
                      <tr className="row_FKLIc1">
                        <td>
                          <Link to={"/tools/underlying/SSIcv1"}>Nikkei 225</Link>
                        </td>
                        <td />
                        <td className="ticktime">{liveIndexFBM.update_time}</td>
                        {liveIndexFBM && liveIndexFBM[liveIndexFBM.keys && liveIndexFBM.keys[1]] && liveIndexFBM[liveIndexFBM.keys && liveIndexFBM.keys[1]].map(
                          (liveIdxFBMData, liveIdxFBMIndex) => (
                            <Fragment key={liveIdxFBMIndex}>
                              <td className="col_expr">
                                {liveIdxFBMData.expiryMonth}
                              </td>
                              <td className="col_bid">
                                {liveIdxFBMData.TRDPRC_1}
                              </td>
                              <td className={`col_net val ${liveIdxFBMData.net > 0 ? "up" : liveIdxFBMData.net < 0 ? "down" : ""}`}>
                                {liveIdxFBMData.net}
                              </td>
                              <td className={`col_pct val ${liveIdxFBMData.pct > 0 ? "up" : liveIdxFBMData.pct < 0 ? "down" : ""}`}>
                                {liveIdxFBMData.pct}
                              </td>
                            </Fragment>
                          )
                        )}
                      </tr>
                      <tr className="row_FKLIc1">
                        <td>
                          <Link to={"/tools/underlying/FKLIc1"}>FBM KLCI</Link>
                        </td>
                        <td />
                        <td className="ticktime">{liveIndexFBM.update_time}</td>
                        {liveIndexFBM && liveIndexFBM[liveIndexFBM.keys && liveIndexFBM.keys[0]] && liveIndexFBM[liveIndexFBM.keys && liveIndexFBM.keys[0]].map(
                          (liveIdxFBMData, liveIdxFBMIndex) => (
                            <Fragment key={liveIdxFBMIndex}>
                              <td className="col_expr">
                                {liveIdxFBMData.expiryMonth}
                              </td>
                              <td className="col_bid">
                                {liveIdxFBMData.TRDPRC_1}
                              </td>
                              <td className={`col_net val ${liveIdxFBMData.net > 0 ? "up" : liveIdxFBMData.net < 0 ? "down" : ""}`}>
                                {liveIdxFBMData.net}
                              </td>
                              <td className={`col_pct val ${liveIdxFBMData.pct > 0 ? "up" : liveIdxFBMData.pct < 0 ? "down" : ""}`}>
                                {liveIdxFBMData.pct}
                              </td>
                            </Fragment>
                          )
                        )}
                      </tr>
                    </tbody>}
                  </table>
                  <div className="priceUpdateInfo">
                    <em>
                      <br />
                      *Click refresh button for live quote
                      <br />{" "}
                      <span
                        className="ttip"
                        data-target="dbpowerdisclaimer2"
                        data-hasqtip={1}
                        data-tip
                        data-for={"tooltiphome_1"}
                      >
                        HSI and HSTECH data provided by DB Power Online Limited
                        [Disclaimer]
                      </span>
                      <ReactTooltip
                        id={"tooltiphome_1"}
                        delayHide={200}
                        place="right"
                        type="warning"
                        effect="solid"
                        clickable={true}
                      >
                        <div className="bg-tooltip">
                          <div className="tooltip-content">
                            DB Power Online Limited, “HKEX Information Services
                            Limited, its holding companies and/or any subsidiaries
                            of such holding companies”, China Investment Information
                            Services Limited, third party information providers
                            endeavor to ensure the accuracy and reliability of the
                            information provided but do not guarantee its accuracy
                            or reliability and accept no liability (whether in tort
                            or contract or otherwise) for any loss or damage arising
                            from any inaccuracies or omissions.
                            <br />
                            <br />
                            The BMP Service is aimed for investor reference. The BMP
                            only provides basic market information for reference
                            purposes and the investors should consider if they would
                            need more detailed market information for reference or
                            to facilitate their investment decision.
                            <br />
                            <img
                              style={{ width: "160px" }}
                              src="/img/home/dbpower.png"
                            />
                          </div>
                        </div>
                      </ReactTooltip>
                    </em>
                  </div>
                </div>
              </div>
              <div className="holder-lmatrix xs-view">
                <div id="lmatrix" className="section">
                  <div className="page-header">
                    <h1 className="small">Live matrix</h1>
                  </div>
                  <p className="section-p">
                    Makes trading warrant easy by showing investors where our
                    bid and offer prices will be throughout the day.
                  </p>


                  <div id="dropdown-livematrix" >
                    {symbol.length > 0 &&
                      <div style={{ textAlignLast: "center" }}>
                        <SelectComp
                          data={symbol}
                          placeholder="Select Warrant"
                          getUnderlyingData={getRic}
                          status="true"
                        />
                      </div>
                    }
                  </div>

                  <p
                    className="section-p select_ric"
                    style={{ display: "none" }}
                  >
                    <em>Please select a warrant to see its live matrix.</em>
                  </p>


                  {ric_id &&
                    <>
                      <div
                        className="ricbar bgcolor-03"
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <div className="ticker" id="ticker">
                          {resultName && `${resultName.dsply_nmll ? resultName.dsply_nmll : ''} ${resultName.ticker ? `(${resultName.ticker})` : ''}`}
                        </div>
                        <Link
                          to={{ pathname: `/tools/livematrix/${resultName?.ticker}` }}
                          style={{ display: "flex" }}
                        >
                          <p style={{ margin: 4 }}>View more</p>{" "}
                          <img src="/img/bullet/qlink-achor.png" />
                        </Link>
                      </div>
                      <div id="lmtablecontainer">
                        <table id="today_table" className="table lbtable">
                          <thead>
                            <tr>
                              <th width="30%">
                                <span className="th-left">Underlying bid</span>
                              </th>
                              <th width="20%">
                                <a
                                  style={{ display: "none" }}
                                  className="icon-refresh color-hyperlink pull-right"
                                />
                                <span className="th-left">Warrant bid</span>
                              </th>
                              <th width="20%">
                                <a
                                  style={{ display: "none" }}
                                  className="icon-refresh color-hyperlink pull-right"
                                />
                                <span className="th-right">Warrant offer</span>
                              </th>
                              <th width="30%">
                                <a
                                  style={{ display: "none" }}
                                  className="icon-refresh color-hyperlink pull-right"
                                />
                                <span className="th-right">Underlying offer</span>
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {result && result.map((d, i) => {
                              return (<tr className={`${d.classList}`} key={i}>
                                <td>{d.underlying_bid}</td>
                                <td>{d.bid}</td>
                                <td>{d.ask}</td>
                                <td>{d.underlying_ask}</td>
                              </tr>)
                            })}
                            {(load && result.length === 0) &&
                              <tr>
                                <td colSpan={6} className="text-content">
                                  There is no matrix available for this warrant, as
                                  the warrant has no value at current levels in the
                                  underlying
                                </td>
                              </tr>
                            }
                          </tbody>
                        </table>
                      </div> </>}



                  <div id="text-content" style={{ display: "none" }}>
                    <table className="table_livematrix_today_empty">
                      <tbody>
                        <tr>
                          <td colSpan={6} className="text-content">
                            There is no matrix available for this warrant, as
                            the warrant has no value at current levels in the
                            underlying
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              <div className="holder-lmatrix xs-view">
                <div id="lmatrix" className="section">
                  <div className="page-header">
                    <h1 className="small">Heatmap pentagon alert</h1>
                  </div>
                  <p className="section-p">
                    The table below shows the underlyings with a Green Pentagon (bullish signal) or a Red Pentagon (bearish signal) appearing in the last 5 trading days. Visit the Warrant Heatmap for more!
                  </p>
                  <div id="recogdiv">
                    <div id="rciframediv">
                      <iframe
                        id="rciframe"
                        style={{ width: "100%", borderStyle: "none", height: "330px" }}
                        src="https://tradevsa.com/macq/pentagon_widget"
                      ></iframe>
                    </div>
                  </div>
                  <div className="section-btn-wapper section-center">
                    <Link
                      to="/tools/heatmap"
                      target="_blank"
                      className="ppbtn btn btn-primary"
                      key="subscribe-newsletter"
                      type="button"
                    >
                      Visit Heatmap
                    </Link>
                  </div>
                </div>
              </div>
              {/* <div className="holder-technicalAnalysis">
                <div id="technicalAnalysis" className="section">
                  <div className="page-header">
                    <h1 className="small">Technical analysis</h1>
                  </div>
                  <div id="topSection">
                    <div className="recogniaUnderlying">
                      <h3 id="defultUnderlying">HSI</h3>
                    </div>
                    <div className="section-btn-wapper">
                      <Link
                        to="/tools/recognia"
                        style={{ minWidth: 190 }}
                        type="button"
                        className="btn btn-primary"
                      >
                        View other underlyings
                      </Link>
                    </div>
                  </div>
                  <div className="recogniaUnderlying">
                    <span id="bid" />
                    <span id="change" />
                    <span id="change_pct" />
                  </div>
                  <div id="rciframediv">
                    <iframe
                      id="rciframe"
                      width="100%"
                      height="560px"
                      frameBorder={0}
                      scrolling="no"
                      src={recogniaurl}
                      style={{ position: "relative" }}
                    >
                      <div className="loadingindicator"></div>
                    </iframe>
                  </div>
                  <div className="section-btn-wapper">
                    <Link
                      to="/tools/recognia?sym=.HSI&exch=INDEX&ticker=HSI&ric=.HSI"
                      style={{ minWidth: 190 }}
                      type="button"
                      className="btn btn-primary"
                    >
                      More details
                    </Link>
                  </div>
                </div>
              </div> */}
              <div className="holder-lmatrix xs-none">
                <div id="lmatrix" className="section">
                  <div className="page-header">
                    <h1 className="small">Heatmap pentagon alert</h1>
                  </div>
                  <p className="section-p">
                    The table below shows the underlyings with a Green Pentagon (bullish signal) or a Red Pentagon (bearish signal) appearing in the last 5 trading days. Visit the Warrant Heatmap for more!
                  </p>
                  <div id="recogdiv">
                    <div id="rciframediv">
                      <iframe
                        id="rciframe"
                        style={{ width: "100%", borderStyle: "none", height: "330px" }}
                        src="https://tradevsa.com/macq/pentagon_widget"
                      ></iframe>
                    </div>
                  </div>
                  <div className="section-btn-wapper section-center">
                    <Link
                      to="/tools/heatmap"
                      target="_blank"
                      className="ppbtn btn btn-primary"
                      key="subscribe-newsletter"
                      type="button"
                    >
                      Visit Heatmap
                    </Link>
                  </div>
                </div>
              </div>

              <div className="holder-quickHeadlines xs-view">
                <div id="quickHeadlines" className="section">
                  <div className="page-header">
                    <h1 className="small">Quick headlines</h1>
                  </div>
                  <div id="topTbl" className="calldw">
                    <table className="table-striped table-responsive">
                      <tbody>
                        <tr className="rowDailyhighlight">
                          <td className="news bgcolor-03">
                            {"Daily highlights"}
                          </td>
                          <td className="newsTitle">
                            {quickHeadlines &&
                              quickHeadlines["highlight"] &&
                              quickHeadlines["highlight"].en_title}
                          </td>
                          <td className="detail">
                            <Link to={quickHeadlines && quickHeadlines["highlight"] ? `/marketnews/highlight?cat=todayhighlight&id=${quickHeadlines["highlight"].id}` : ""}>
                              <img src="/img/bullet/qlink-achor.png" />
                            </Link>
                          </td>
                        </tr>
                        <tr className="rowDailyhighlight">
                          <td className="news bgcolor-03">
                            {"End of day insights"}
                          </td>
                          <td className="newsTitle">
                            {quickHeadlines &&
                              quickHeadlines["commentary"] &&
                              quickHeadlines["commentary"].en_title}
                          </td>
                          <td className="detail">
                            <Link to={quickHeadlines && quickHeadlines["commentary"] ? `/marketnews/highlight?cat=dailycommentary&id=${quickHeadlines["commentary"].id}` : ""}>
                              <img src="/img/bullet/qlink-achor.png" />
                            </Link>
                          </td>
                        </tr>
                        <tr className="rowDailyhighlight">
                          <td className="news bgcolor-03">
                            {"Overnight market wrap"}
                          </td>
                          <td className="newsTitle">
                            {quickHeadlines &&
                              quickHeadlines["overnight"] &&
                              <span dangerouslySetInnerHTML={{ __html: quickHeadlines["overnight"].en_title }}></span>}
                          </td>
                          <td className="detail">
                            <Link to={quickHeadlines && quickHeadlines["overnight"] ? `/marketnews/highlight?cat=overnightmarkets&id=${quickHeadlines["overnight"].id}` : ""}>
                              <img src="/img/bullet/qlink-achor.png" />
                            </Link>
                          </td>
                        </tr>


                      </tbody>
                    </table>
                  </div>
                  <div className="section-btn-wapper section-center">
                    <p className="section-txt">
                      Sign up for our daily newsletter for the latest updates on
                      the market
                    </p>
                    <button
                      onClick={() => setOpen(true)}
                      className="ppbtn btn btn-primary"
                      key="subscribe-newsletter"
                      style={{ minWidth: 190 }}
                      type="button"
                    >
                      Subscribe
                    </button>
                  </div>
                </div>
              </div>
              <div className="holder-qlink-dwsearch">
                <div id="qlink-dwsearch" className="section">
                  <div className="page-header">
                    <h1 className="small">Warrant search</h1>
                  </div>
                  <p className="section-p">
                    Find the right warrant according to your investment
                    criteria.
                  </p>
                  <div className="filter-item first">
                    <Select
                      onChange={(e) => setUnderlyingSelected(e.value)}
                      placeholder={"All underlying"}
                      className="Warrent_dropdown"
                      options={symbolListNew && symbolListNew.map((d) => {
                        return { value: d.symbol, label: d.label }
                      })}
                      styles={colourStyles}
                    />
                  </div>
                  <div className="filter-item">
                    <Select
                      onChange={(e) => setCallPutSelected(e.value)}
                      placeholder={"Call/Put"}
                      className="Warrent_dropdown"
                      options={callPut}
                      styles={colourStyles2}
                      isSearchable={false}
                    />
                  </div>
                  <div className="filter-item">
                    <Select
                      onChange={(e) => setExpirySelected(e.value)}
                      placeholder={"All expiry"}
                      className="Warrent_dropdown"
                      options={expiryDates}
                      styles={colourStyles2}
                      isSearchable={false}
                    />
                  </div>
                  <div className="section-btn-wapper section-center">
                    <Link to={redirectToWarrantSearch()}>
                      <button
                        id="searchSubmitBtn"
                        type="button"
                        className="btn btn-primary searchSubmitBtn"
                      >
                        Submit
                      </button>
                    </Link>
                    <Link
                      to={redirectToWarrantSearch()}
                      style={{}}
                      type="button"
                      className="btn btn-primary"
                    >
                      View all underlyings
                    </Link>
                  </div>
                </div>
              </div>
              <div className="holder-toptradedstock">
                <div id="toptradedstock" className="section">
                  <div className="page-header">
                    <h1 className="small">Top traded stocks</h1>
                  </div>
                  <p className="section-txt">
                    View the most active underlying shares (by value) and the
                    corresponding structured warrant with the highest liquidity.
                  </p>

                  <div id="topTbl" className="calldw">
                    <table className="table-striped table-responsive">
                      <thead>
                        <tr>
                          <th className="bgcolor-04" colSpan={3}>
                            Underlying
                          </th>
                          <th className="col_call bgcolor-06" colSpan={3}>
                            Call Warrant
                          </th>
                        </tr>
                        <tr>
                          <th className="bgcolor-04 top-underlying">
                            Underlying
                          </th>
                          <th className="bgcolor-04">
                            Turnover
                            <span className="xs-none">
                              <br />
                              (MYR '000)
                            </span>
                            <span className="xs-view">
                              (MYR mil)
                            </span>
                          </th>
                          <th className="bgcolor-04">
                            Change
                            <br />
                            (%)
                          </th>
                          <th className="col_call bgcolor-06">
                            Warrant
                            <br />
                            name
                          </th>
                          <th className="col_call bgcolor-06">
                            Bid
                            <br />
                            (MYR)
                          </th>
                          <th className="col_call bgcolor-06 top-expiry">
                            Expiry
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {topTradedStock &&
                          topTradedStock
                            .slice(0, 5)
                            .map((topData, topIndex) => (
                              <tr
                                className="rowtmpl hide-putdw"
                                key={topIndex}
                              >
                                <td className="col_underlying_ticker">
                                  {topData.underlying_ticker}
                                </td>
                                <td className="col_long col_undelrying_turnover xs-none">
                                  {/* {parseInt(topData.undelrying_turnover)} */}
                                  {topData && topData.undelrying_turnover &&
                                    (Math.round(topData.undelrying_turnover / 1000)).toLocaleString()}
                                </td>
                                <td className="col_long col_undelrying_turnover xs-view">
                                  {/* {parseInt(topData.undelrying_turnover)} */}
                                  {topData && topData.undelrying_turnover &&
                                    (topData.undelrying_turnover / 1000000).toFixed(2)}
                                </td>
                                <td className={`right-b01 col_PCTCHNG ${+topData.PCTCHNG > 0 ? "up" : +topData.PCTCHNG < 0 ? "down" : ""}`}>
                                  {topData.PCTCHNG.includes("-") ? topData.PCTCHNG : +topData.PCTCHNG > 0 ? "+" + topData.PCTCHNG : topData.PCTCHNG}
                                </td>

                                <td className="col_call col_dw_ticker">
                                  <Link to={`/tools/livematrix/${topData.dw_ticker_c}`}>
                                    {topData.dw_dsply_nmll_c}
                                  </Link>
                                </td>
                                <td className="col_call col_warrant_price">
                                  {topData.warrant_price_c}
                                </td>
                                <td className="col_call col_expiry">
                                  {moment(new Date(topData.maturity)).format("DD MMM YY")}
                                </td>
                              </tr>
                            ))}
                      </tbody>
                    </table>
                  </div>

                  <div className="lastupdatebox">
                    Last update :{" "}
                    <span>
                      {moment(new Date(topTradedStock[0]?.update_time)).format("DD MMM YY")}
                    </span>
                  </div>
                  <div className="section-btn-wapper">
                    <Link
                      to="/marketdata/toptradedstocks"
                      style={{ minWidth: 190 }}
                      type="button"
                      className="btn btn-primary"
                    >
                      View more
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-sm-6">
              <div className="holder-indexfuture xs-none">
                <div id="indexfuture" className="section">
                  <div
                    id="LiveIndexFutures"
                    style={{
                      marginTop: "-50px",
                      position: "absolute",
                    }}
                  />
                  <div className="page-header">
                    <h1 className="small">Live index futures</h1>
                  </div>
                  <table className="table table-striped table-noHeaderborder">
                    <thead>
                      <tr>
                        <th>Index</th>
                        <th />
                        <th>As at</th>
                        <th>Contract</th>
                        <th>Last</th>
                        <th>Change</th>
                        <th>Change%</th>
                      </tr>
                    </thead>
                    {liveIndexHSI.code && liveIndexHTI.code && <tbody>
                      <tr className="row_HSI">
                        <td>
                          <Link to={"/tools/underlying/HSIc1"}>HSI*</Link>
                        </td>
                        <td rowSpan={2} style={{ verticalAlign: "middle" }}>
                          <img
                            className="refresh_index"
                            style={{
                              cursor: "pointer",
                              marginLeft: 5,
                              width: 22,
                            }}
                            onClick={() => refreshLiveIndex()}
                            src="/img/bullet/refresh.png"
                          />
                        </td>
                        <td
                          rowSpan={2}
                          style={{ verticalAlign: "middle" }}
                          className="ticktime_HSI"
                        >
                          {moment(new Date(liveIndexHSI.stime)).format("hh:mm:ss")}
                          {/* :{liveIndexHSISec} */}
                          {new Date(liveIndexHSI.stime).getHours() > 11 ? " PM" : " AM"}
                        </td>
                        <td className="col_month">{liveIndexHSI.month}</td>
                        <td className="col_last">{liveIndexHSI.last.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</td>
                        <td className={`col_chng val ${liveIndexHSI.chng > 0 ? "up" : liveIndexHSI.chng < 0 ? "down" : ""}`}>
                          {liveIndexHSI.chng == 0 ? 0 : liveIndexHSI.chng.includes("-") ? liveIndexHSI.chng : "+" + liveIndexHSI.chng}
                        </td>
                        <td className={`col_pchng val ${Number(liveIndexHSI.pchng.replace('%', '')) > 0 ? "up" : Number(liveIndexHSI.pchng.replace('%', '')) < 0 ? "down" : ""}`}>
                          {liveIndexHSI.pchng == 0 ? 0 : liveIndexHSI.pchng.includes("-") ? liveIndexHSI.pchng.replace('%', '') : "+" + liveIndexHSI.pchng.replace('%', '')}
                        </td>
                      </tr>
                      <tr className="row_HTI">
                        <td>
                          <Link to={"/tools/underlying/HHTIc1"}>HSTECH*</Link>
                        </td>

                        <td className="col_month">{liveIndexHTI.month}</td>
                        <td className="col_last">{liveIndexHTI.last.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</td>
                        <td className={`col_chng val ${liveIndexHTI.chng > 0 ? "up" : liveIndexHTI.chng < 0 ? "down" : ""}`}>
                          {liveIndexHTI.chng == 0 ? 0 : liveIndexHTI.chng.includes("-") ? liveIndexHTI.chng : "+" + liveIndexHTI.chng}
                        </td>
                        <td className={`col_pchng val ${Number(liveIndexHTI.pchng.replace('%', '')) > 0 ? "up" : Number(liveIndexHTI.pchng.replace('%', '')) < 0 ? "down" : ""}`}>
                          {liveIndexHTI.pchng == 0 ? 0 : liveIndexHTI.pchng.includes("-") ? liveIndexHTI.pchng.replace('%', '') : "+" + liveIndexHTI.pchng.replace('%', '')}
                        </td>
                      </tr>
                      <tr className="row_FKLIc1">
                        <td>
                          <Link to={"/tools/underlying/SSIcv1"}>Nikkei 225</Link>
                        </td>
                        <td />
                        <td className="ticktime">{liveIndexFBM.update_time}</td>
                        {liveIndexFBM &&
                          liveIndexFBM[liveIndexFBM.keys && liveIndexFBM.keys[1]] &&
                          liveIndexFBM[liveIndexFBM.keys && liveIndexFBM.keys[1]].map(
                            (liveIdxFBMData, liveIdxFBMIndex) => (
                              <Fragment key={liveIdxFBMIndex}>
                                <td className="col_expr">
                                  {liveIdxFBMData.expiryMonth != null ? liveIdxFBMData.expiryMonth : liveIdxFBMData.expiryMonth == "" ? "-" : "-"}
                                </td>
                                <td className="col_bid">
                                  {liveIdxFBMData.TRDPRC_1 != null ? liveIdxFBMData.TRDPRC_1 : liveIdxFBMData.TRDPRC_1 == "" ? "-" : "-"}
                                </td>
                                <td className={`col_net val ${liveIdxFBMData.net > 0 ? "up" : liveIdxFBMData.net < 0 ? "down" : ""}`}>
                                  {liveIdxFBMData.net != null ? liveIdxFBMData.net : liveIdxFBMData.net == "" ? "-" : "-"}
                                </td>
                                <td className={`col_pct val ${liveIdxFBMData.pct > 0 ? "up" : liveIdxFBMData.pct < 0 ? "down" : ""}`}>
                                  {liveIdxFBMData.pct != null ? liveIdxFBMData.pct : liveIdxFBMData.pct == "" ? "-" : "-"}
                                </td>
                              </Fragment>
                            )
                          )}
                      </tr>
                      <tr className="row_FKLIc1">
                        <td>
                          <Link to={"/tools/underlying/FKLIc1"}>FBM KLCI</Link>
                        </td>
                        <td />
                        <td className="ticktime">{liveIndexFBM.update_time}</td>
                        {liveIndexFBM &&
                          liveIndexFBM[liveIndexFBM.keys && liveIndexFBM.keys[0]] &&
                          liveIndexFBM[liveIndexFBM.keys && liveIndexFBM.keys[0]].map(
                            (liveIdxFBMData, liveIdxFBMIndex) => (
                              <Fragment key={liveIdxFBMIndex}>
                                <td className="col_expr">
                                  {liveIdxFBMData.expiryMonth != null ? liveIdxFBMData.expiryMonth : liveIdxFBMData.expiryMonth == "" ? "-" : "-"}
                                </td>
                                <td className="col_bid">
                                  {liveIdxFBMData.TRDPRC_1 != null ? liveIdxFBMData.TRDPRC_1 : liveIdxFBMData.TRDPRC_1 == "" ? "-" : "-"}
                                </td>
                                <td className={`col_net val ${liveIdxFBMData.net > 0 ? "up" : liveIdxFBMData.net < 0 ? "down" : ""}`}>
                                  {liveIdxFBMData.net != null ? liveIdxFBMData.net : liveIdxFBMData.net == "" ? "-" : "-"}
                                </td>
                                <td className={`col_pct val ${liveIdxFBMData.pct > 0 ? "up" : liveIdxFBMData.pct < 0 ? "down" : ""}`}>
                                  {liveIdxFBMData.pct != null ? liveIdxFBMData.pct : liveIdxFBMData.pct == "" ? "-" : "-"}
                                </td>
                              </Fragment>
                            )
                          )}
                      </tr>
                    </tbody>}
                  </table>
                  <div className="priceUpdateInfo">
                    <em>
                      <br />
                      *Click refresh button for live quote
                      <br />{" "}
                      <span
                        className="ttip"
                        data-target="dbpowerdisclaimer2"
                        data-hasqtip={1}
                        data-tip
                        data-for={"tooltiphome_1"}

                      >
                        HSI and HSTECH data provided by DB Power Online Limited
                        [Disclaimer]
                      </span>
                      <ReactTooltip
                        id={"tooltiphome_1"}
                        delayHide={200}
                        // offset={-50}
                        place="right"
                        type="warning"
                        effect="solid"
                        clickable={true}
                      >
                        <div className="bg-tooltip">
                          <div className="tooltip-content">
                            DB Power Online Limited, “HKEX Information Services
                            Limited, its holding companies and/or any subsidiaries
                            of such holding companies”, China Investment Information
                            Services Limited, third party information providers
                            endeavor to ensure the accuracy and reliability of the
                            information provided but do not guarantee its accuracy
                            or reliability and accept no liability (whether in tort
                            or contract or otherwise) for any loss or damage arising
                            from any inaccuracies or omissions.
                            <br />
                            <br />
                            The BMP Service is aimed for investor reference. The BMP
                            only provides basic market information for reference
                            purposes and the investors should consider if they would
                            need more detailed market information for reference or
                            to facilitate their investment decision.
                            <br />
                            <img
                              style={{ width: "160px" }}
                              src="/img/home/dbpower.png"
                            />
                          </div>
                        </div>
                      </ReactTooltip>
                    </em>
                  </div>
                </div>
              </div>
              <div className="holder-lmatrix xs-none">
                <div id="lmatrix" className="section">
                  <div className="page-header">
                    <h1 className="small">Live matrix</h1>
                  </div>
                  <p className="section-p">
                    Makes trading warrant easy by showing investors where our
                    bid and offer prices will be throughout the day.
                  </p>


                  <div id="dropdown-livematrix" >
                    {symbol.length > 0 &&
                      <div style={{ textAlignLast: "center" }}>
                        <SelectComp
                          data={symbol}
                          placeholder="Select Warrant"
                          getUnderlyingData={getRic}
                          status="true"
                        />
                      </div>
                    }
                  </div>

                  <p
                    className="section-p select_ric"
                    style={{ marginTop: "10px" }}
                  >
                    <em>Please select a warrant to see its live matrix.</em>
                  </p>

                  {ric_id &&
                    <> <div
                      className="ricbar bgcolor-03"
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <div className="ticker" id="ticker">

                        {resultName && `${resultName.dsply_nmll ? resultName.dsply_nmll : ''} ${resultName.ticker ? `(${resultName.ticker})` : ''}`}
                      </div>
                      <Link
                        to={{ pathname: `/tools/livematrix/${resultName?.ticker}` }}
                        style={{ display: "flex" }}
                      >
                        <p style={{ margin: 4 }}>View more</p>{" "}
                        <img src="/img/bullet/qlink-achor.png" />
                      </Link>
                    </div>
                      <div id="lmtablecontainer">
                        <table id="today_table" className="table lbtable">
                          <thead>
                            <tr>
                              <th width="30%">
                                <span className="th-left">Underlying bid</span>
                              </th>
                              <th width="20%">
                                <a
                                  style={{ display: "none" }}
                                  className="icon-refresh color-hyperlink pull-right"
                                />
                                <span className="th-left">Warrant bid</span>
                              </th>
                              <th width="20%">
                                <a
                                  style={{ display: "none" }}
                                  className="icon-refresh color-hyperlink pull-right"
                                />
                                <span className="th-right">Warrant offer</span>
                              </th>
                              <th width="30%">
                                <a
                                  style={{ display: "none" }}
                                  className="icon-refresh color-hyperlink pull-right"
                                />
                                <span className="th-right">Underlying offer</span>
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {result && result.map((d, i) => {
                              return (<tr className={`${d.classList}`} key={i}>
                                <td>{d.underlying_bid}</td>
                                <td>{d.bid}</td>
                                <td>{d.ask}</td>
                                <td>{d.underlying_ask}</td>
                              </tr>)
                            })}
                            {(load && result.length === 0) &&
                              <tr>
                                <td colSpan={6} className="text-content">
                                  There is no matrix available for this warrant, as
                                  the warrant has no value at current levels in the
                                  underlying
                                </td>
                              </tr>
                            }
                          </tbody>
                        </table>
                      </div></>}

                  <div id="text-content" style={{ display: "none" }}>
                    <table className="table_livematrix_today_empty">
                      <tbody>
                        <tr>
                          <td colSpan={6} className="text-content">
                            There is no matrix available for this warrant, as
                            the warrant has no value at current levels in the
                            underlying
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>



              <div className="holder-qlink-dwchart xs-none">
                <div id="qlink-dwchart" className="section">
                  <div className="page-header">
                    <h1 className="small">Warrant terms</h1>
                  </div>
                  <p>
                    Everything you need to know about your selected warrant is
                    found here.
                  </p>
                  <div>
                    <img
                      style={{ height: 160, width: "100%", maxWidth: 600 }}
                      src="/img/home/dwchart-tmp.png"
                    />
                  </div>
                  <div className="section-btn-wapper">
                    <Link
                      to="/tools/warrantterms"
                      type="button"
                      className="btn btn-primary"
                    >
                      View more
                    </Link>
                  </div>
                </div>
              </div>
              <div className="holder-announcement">
                <div id="announcement">
                  <div className="section announcement">
                    <div
                      id="WarrantAnnouncement"
                      style={{
                        marginTop: "-50px",
                        position: "absolute",
                      }}
                    />
                    <div className="page-header">
                      <h2 className="small">Warrant announcements</h2>
                    </div>
                    <div className="announcement-list">
                      <div className="row">
                        <div className="announcement-col  col-xs-12 col-md-12">
                          {staticData && staticData.holiday_announcement.length > 0 && (moment(new Date(staticData.holiday_announcement[0].end_date)).valueOf()) >= (moment(new Date()).valueOf()) ?
                            <div className="media holiday">
                              <div className="media-left">
                                <img
                                  className="media-object"
                                  src="/img/bullet/ann_staticbrand.png"
                                  alt="general announcement"
                                />
                              </div>
                              <div className="media-body">
                                <h4 className="media-heading">
                                  {staticData && staticData.holiday_announcement && staticData.holiday_announcement[0].en_title}
                                  <br />
                                  <span className="date">{staticData && staticData.holiday_announcement && moment(new Date(staticData.holiday_announcement[0].publish_date)).format("MMMM DD, YYYY")}</span>
                                </h4>
                                {staticData && staticData.holiday_announcement && <p dangerouslySetInnerHTML={{ __html: staticData.holiday_announcement[0].en_short_content }}></p>}
                                <p className="media-readmore">
                                  <span
                                    className="holiday-readmore"
                                  >
                                    <a target="_blank" href={staticData && staticData.holiday_announcement && staticData.holiday_announcement[0].redirect_url}>Read more</a>
                                  </span>
                                </p>
                              </div>
                            </div> : ''
                          }
                        </div>
                        <div className="announcement-col  col-xs-12 col-md-12">
                          {staticData && staticData.WarrantAnnouncements.length > 0 && (moment(new Date(staticData.WarrantAnnouncements[0].end_date)).valueOf()) >= (moment(new Date()).valueOf()) ?
                            <div className="media general">
                              <div className="media-left">
                                <img
                                  className="media-object"
                                  src="/img/bullet/ann_general.png"
                                  alt="general announcement"
                                />
                              </div>
                              <div className="media-body">
                                <h4 className="media-heading">
                                  {staticData && staticData.WarrantAnnouncements && staticData.WarrantAnnouncements[0].en_title}
                                  <br />
                                  <span className="date">{staticData && staticData.WarrantAnnouncements && moment(new Date(staticData.WarrantAnnouncements[0].publish_date)).format("MMMM DD, YYYY")}</span>
                                </h4>
                                {staticData && staticData.WarrantAnnouncements && <p dangerouslySetInnerHTML={{ __html: staticData.WarrantAnnouncements[0].en_short_content }}></p>}
                                <p className="media-readmore">
                                  <span
                                    onClick={() => setOpenLive(true)}
                                    className="general-readmore"
                                  >
                                    Read more
                                  </span>
                                </p>
                              </div>
                            </div> : ''
                          }
                        </div>
                        <div className="announcement-col  col-xs-12 col-md-12">
                          <div
                            id="WarrantAnnouncementSoldout"
                            style={{
                              marginTop: "-50px",
                              position: "absolute",
                            }}
                          />
                          {soldOut.length > 0 ?
                            <div className="media soldout">
                              <div className="media-left">
                                <img
                                  className="media-object"
                                  src="/img/bullet/ann_soldout.png"
                                  alt="Sold-out warrants"
                                />
                              </div>
                              <div className="media-body">
                                <h4 className="media-heading">
                                  Sold-out warrants
                                </h4>
                                <p>
                                  The issuer has sold all of the inventory for
                                  this warrant or is approaching this point.
                                </p>
                                <table className="announcement-nolink-table table table-responsive">
                                  <tbody>
                                    <tr>
                                      <td>
                                        <div className="row">
                                          {soldOut.map((data, idx) => {
                                            if (viewMoreSoldout || (!viewMoreSoldout && idx < 6)) {
                                              return (
                                                <div
                                                  className="col-md-6 col-sm-6 text-center sold-out"
                                                  key={idx}
                                                >
                                                  <p className="text-bold">{data.dsply_nmll}</p>
                                                </div>
                                              )
                                            }
                                          }
                                          )}
                                        </div>
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                                {viewMoreSoldout ? (
                                  <p
                                    onClick={() =>
                                      setviewMoreSoldout(false)
                                    }
                                    className="media-readmore"
                                  >
                                    <span className="staticbrand-readmore">
                                      Hide
                                    </span>
                                  </p>
                                ) : (
                                  <p
                                    onClick={() =>
                                      setviewMoreSoldout(true)
                                    }
                                    className="media-readmore"
                                  >
                                    <span className="staticbrand-readmore">
                                      View all
                                    </span>
                                  </p>
                                )}
                              </div>
                            </div> : ''
                          }
                        </div>
                      </div>
                      <div className="row">
                        <div className="announcement-col  col-xs-12 col-md-12">
                          <div
                            id="WarrantAnnouncementDynamicStatic"
                            style={{
                              marginTop: "-50px",
                              position: "absolute",
                            }}
                          />
                          {staticData && staticData.StaticData.length > 0 ?
                            <div className="media staticband">
                              <div className="media-left">
                                <img
                                  className="media-object"
                                  src="/img/bullet/ann_staticbrand.png"
                                  alt="Static band"
                                />
                              </div>
                              <div className="media-body">
                                <h4 className="media-heading">
                                  Dynamic / static price band
                                </h4>
                                <p>
                                  We are currently unable to provide liquidity in
                                  the following warrants as they have exceeded the
                                  dynamic / static price band set by the Bursa.
                                  For anyone wanting to trade in these warrants,
                                  please call our warrant desk on +603 2059 8840.
                                </p>
                                <table className="announcement-nolink-table table table-responsive">
                                  <tbody>
                                    <>
                                      {staticData && staticData.StaticData && staticData.StaticData.map((item, index) => {
                                        if (index % 2 === 0 && (viewMoreDynamicStaticPrice || (!viewMoreDynamicStaticPrice && index < 6))) {
                                          return (
                                            <tr key={index}>
                                              <td>{item.dsply_nmll}</td>
                                              <td>{staticData?.StaticData[index + 1] ? staticData?.StaticData[index + 1].dsply_nmll : null}</td>
                                            </tr>
                                          )
                                        }
                                      })
                                      }
                                    </>
                                  </tbody>
                                </table>
                                {viewMoreDynamicStaticPrice ? (
                                  <p
                                    onClick={() =>
                                      setViewMoreDynamicStaticPrice(false)
                                    }
                                    className="media-readmore"
                                  >
                                    <span className="staticbrand-readmore">
                                      Hide
                                    </span>
                                  </p>
                                ) : (
                                  <p
                                    onClick={() =>
                                      setViewMoreDynamicStaticPrice(true)
                                    }
                                    className="media-readmore"
                                  >
                                    <span className="staticbrand-readmore">
                                      View all
                                    </span>
                                  </p>
                                )}
                              </div>
                            </div> : ''
                          }
                        </div>
                        <div className="announcement-col  col-xs-12 col-md-12">
                          <div
                            id="WarrantAnnouncementExpiring"
                            style={{
                              marginTop: "-50px",
                              position: "absolute",
                            }}
                          />
                          {staticData && staticData.expiringWarrants.length > 0 ?
                            <div className="media expiring">
                              <div className="media-left">
                                <img
                                  className="media-object"
                                  src="/img/bullet/ann_expiring.png"
                                  alt="Expiring warrants"
                                />
                              </div>
                              <div className="media-body">
                                <h4 className="media-heading">
                                  Expiring warrants
                                </h4>
                                <p>
                                  These warrants have less than 1 month to expiry.
                                </p>
                                <table className="announcement-table table table-responsive">
                                  <tbody>
                                    <>
                                      {staticData && staticData.expiringWarrants && staticData.expiringWarrants.map((item, index) => {
                                        if (index % 2 === 0 && (viewMoreExpiryWarrarnt || (!viewMoreExpiryWarrarnt && index < 6))) {
                                          return (
                                            <tr key={index}>
                                              <td>
                                                <Link to="/edu/single-share-calls">
                                                  {item.dsply_nmll}
                                                </Link>
                                              </td>
                                              <td>
                                                <Link to="/edu/single-share-calls">
                                                  {staticData?.expiringWarrants[index + 1] ? staticData?.expiringWarrants[index + 1].dsply_nmll : null}
                                                </Link>
                                              </td>
                                            </tr>
                                          )
                                        }
                                      })
                                      }
                                    </>
                                  </tbody>
                                </table>
                                {viewMoreExpiryWarrarnt ? (
                                  <p
                                    onClick={() =>
                                      setViewMoreExpiryWarrarnt(false)
                                    }
                                    className="media-readmore"
                                  >
                                    <span className="expiringwarrants-readmore">
                                      Hide
                                    </span>
                                  </p>
                                ) : (
                                  <p
                                    onClick={() =>
                                      setViewMoreExpiryWarrarnt(true)
                                    }
                                    className="media-readmore"
                                  >
                                    <span className="expiringwarrants-readmore">
                                      View all
                                    </span>
                                  </p>
                                )}
                              </div>
                            </div> : ''}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="holder-quickHeadlines xs-none">
                <div id="quickHeadlines" className="section">
                  <div className="page-header">
                    <h1 className="small">Quick headlines</h1>
                  </div>
                  <div id="topTbl" className="calldw">
                    <table className="table-striped table-responsive">
                      <tbody>
                        <tr className="rowDailyhighlight">
                          <td className="news bgcolor-03">
                            {"Daily highlights"}
                          </td>
                          <td className="newsTitle">
                            {quickHeadlines &&
                              quickHeadlines["highlight"] &&
                              quickHeadlines["highlight"].en_title}
                          </td>
                          <td className="detail">
                            <Link to={quickHeadlines && quickHeadlines["highlight"] ? `/marketnews/highlight?cat=todayhighlight&id=${quickHeadlines["highlight"].id}` : ""}>
                              <img src="/img/bullet/qlink-achor.png" />
                            </Link>
                          </td>
                        </tr>
                        <tr className="rowDailyhighlight">
                          <td className="news bgcolor-03">
                            {"End of day insights"}
                          </td>
                          <td className="newsTitle">
                            {quickHeadlines &&
                              quickHeadlines["commentary"] &&
                              quickHeadlines["commentary"].en_title}
                          </td>
                          <td className="detail">
                            <Link to={quickHeadlines && quickHeadlines["commentary"] ? `/marketnews/highlight?cat=dailycommentary&id=${quickHeadlines["commentary"].id}` : ""}>
                              <img src="/img/bullet/qlink-achor.png" />
                            </Link>
                          </td>
                        </tr>
                        <tr className="rowDailyhighlight">
                          <td className="news bgcolor-03">
                            {"Overnight market wrap"}
                          </td>
                          <td className="newsTitle">
                            {quickHeadlines &&
                              quickHeadlines["overnight"] &&
                              <span dangerouslySetInnerHTML={{ __html: quickHeadlines["overnight"].en_title }}></span>}
                          </td>
                          <td className="detail">
                            <Link to={quickHeadlines && quickHeadlines["overnight"] ? `/marketnews/highlight?cat=overnightmarkets&id=${quickHeadlines["overnight"].id}` : ""}>
                              <img src="/img/bullet/qlink-achor.png" />
                            </Link>
                          </td>
                        </tr>


                      </tbody>
                    </table>
                  </div>
                  <div className="section-btn-wapper section-center">
                    <p className="section-txt">
                      Sign up for our daily newsletter for the latest updates on
                      the market
                    </p>
                    <button
                      onClick={() => setOpen(true)}
                      className="ppbtn btn btn-primary"
                      key="subscribe-newsletter"
                      style={{ minWidth: 190 }}
                      type="button"
                    >
                      Subscribe
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div></div>
      <LiveMatrixPlusModal
        Open={openLive}
        HandleModel={() => setOpenLive(!openLive)}
        data={staticData?.WarrantAnnouncements}
      />
    </>
  );
};
export default WarrantTool;
