import React from 'react';
import { Link } from 'react-router-dom';





const Footer = () => {

  return (
    <div className="footer">
      <div className="container-fluid">
        <div className="sitemap sitemap_desk">
          <div className="sitemap-col">
            <h3 className="color-primary">Warrant tools</h3>
            <ul>
              <li>
                <Link to="/tools/livematrix/">Live Matrix Plus</Link>
              </li>
              <li>
                <Link to="/tools/warrantsearch">Warrant search</Link>
              </li>
              <li>
                <Link to="/tools/warrantterms">Warrant terms</Link>
              </li>
              <li>
                <Link to="/tools/warrantcalculator">Warrant calculator</Link>
              </li>
              <li>
                <Link to="/tools/underlying">Underlying charts</Link>
              </li>
              <li>
                <Link to="/tools/warrantcomparison">Warrant comparison</Link>
              </li>
              <li>
                <Link to="/tools/document">Warrant documents</Link>
              </li>
              <li>
                <Link to="/tools/heatmap">Warrant heatmap</Link>
              </li>
              {/* <li>
                <Link to="/tools/recognia">Technical Event® Lookup</Link>
              </li> */}
            </ul>
          </div>
          <div className="sitemap-col">
            <h3 className="color-primary">Warrant statistics</h3>
            <ul>
              <li>
                <Link to="/marketdata/toptradedstocks">Top traded stocks</Link>
              </li>
              <li>
                <Link to="/marketdata/toptradedwarrants">Top traded warrants</Link>
              </li>
              <li>
                <Link to="/marketdata/turnoverdist">Turnover distribution</Link>
              </li>
              <li>
                <Link to="/marketdata/turnover">Warrant market turnover</Link>
              </li>
              <li>
                <Link to="/marketdata/moneyflow">Money flow</Link>
              </li>
            </ul>
          </div>
          <div className="sitemap-col">
            <h3 className="color-primary">Warrant education</h3>
            <ul>
              <div
                style={{
                  margin: 0,
                  padding: 0,
                  borderBottom: "none",
                  fontSize: 14,
                  color: "#fff",
                  paddingBottom: 4,
                  fontFamily: '"MCQGlobal_W_Md"'
                }}
              >
                <div
                  data-bs-toggle="collapse"
                  data-bs-target="#mblmenu12"
                  style={{ cursor: "pointer" }}
                >
                  <li className="d-flex justify-content-between align-items-center">
                    New to warrants
                    <i className="fa fa-angle-down float-right"></i>
                  </li>
                </div>
                <div
                  id="mblmenu12"
                  className="navmbl-panel-collapse-mdlg collapse"
                  style={{ margin: 0, fontWeight: 400 }}
                >
                  <li>
                    <Link to="/education/getstarted">How to get started</Link>
                  </li>
                  <li>
                    <Link to="/FirstStep">Your first step</Link>
                  </li>
                 
                  <li>
                    <Link to="/education/warrantvideos">Educational videos</Link>
                  </li>
                  <li>
                    <Link to="/SuccessSpeaks">Success Speaks</Link>
                  </li>
                </div>
              </div>
              <div
                style={{
                  margin: 0,
                  padding: 0,
                  borderBottom: "none",
                  fontSize: 14,
                  color: "#fff",
                  paddingBottom: 4,
                  fontFamily: '"MCQGlobal_W_Md"'
                }}
              >
                <div
                  data-bs-toggle="collapse"
                  data-bs-target="#mblmenu13"
                  style={{ cursor: "pointer" }}
                >

                  <li className="d-flex justify-content-between align-items-center">
                    Existing warrant investors
                    <i className="fa fa-angle-down float-right"></i>
                  </li>
                </div>
                <div
                  id="mblmenu13"
                  className="navmbl-panel-collapse-mdlg collapse"
                  style={{ margin: 0, fontWeight: 400 }}
                >
                  <li>
                    <Link to="/education/warrantvideos/#GuestSpeakerVideo">
                      Guest speaker videos
                    </Link>
                  </li>
                  <li>
                    <Link to="/education/indexwarrants">
                      Index warrants
                    </Link>
                  </li>
                  <li>
                    <Link to="/education/whymacquarie">
                      Why Macquarie?
                    </Link>
                  </li>
                  <li>
                    <Link to="/education/warrantvideosind/y7Zkk7yuoDs/6">
                      How to use the Live Matrix
                    </Link>
                  </li>
                </div>
              </div>
               {/* <li>
            <Link  to="/warrant-education/HKunderlyings">Gain exposure to HK market</Link>
            </li> */}
              {/* <li>
            <Link  to="/warrant-education/gain-exposure">Gain exposure to HK market</Link>
            </li> */}
              <li>
                <Link to="/edu">
                  Investor academy
                </Link>
              </li>
              
              <li>
                <Link to="/education/faq" >
                  Frequently asked questions
                </Link>
              </li>
              <li>
                <Link to="/education/seminar">Seminars</Link>
              </li>
              
            </ul>
          </div>
          <div className="sitemap-col clearfix">
            <h3 className="color-primary">Market news</h3>
            <ul>
              <li>
                <Link to="/telegram">Join us on Telegram</Link>
              </li>
              <li>
                <Link to="/marketnews/dailyhighlights">Daily highlights</Link>
              </li>
              <li>
                <Link to="/marketnews/eodinsights">End of day insights</Link>
              </li>
              <li>
                <Link to="/marketnews/overnightmarkets">Overnight market wrap</Link>
              </li>
              <li>
                <Link to="/marketnews/subscribe">Subscribe</Link>
              </li>
              <li>
                <Link to="/marketnews/activities">Activities</Link>
              </li>
              <li>
                <Link to="/marketnews/photogallery">Photo gallery</Link>
              </li>
            </ul>
          </div>
        </div>

        <div className="sitemap sitemap_mob">
          <div className="sitemap-col">
            <ul>
              <div style={{
                margin: 0, padding: 0, borderBottom: "none", fontSize: 14, color: "#fff", paddingBottom:
                  4, fontFamily: '"MCQGlobal_W_Md"'
              }}>
                <div data-bs-toggle="collapse" data-bs-target="#mblmenu1" style={{ cursor: "pointer" }}>
                  <li className="d-flex justify-content-between align-items-center color-primary first_li">
                    <i className="fa fa-angle-down float-right"></i>
                    Warrant tools
                  </li>
                </div>
                <div id="mblmenu1" className="navmbl-panel-collapse-mdlg collapse" style={{ margin: 0, fontWeight: 400, borderTop: "1px solid rgb(255, 255, 255, 0.5)" }}>

                  <li>
                    <Link to="/tools/livematrix/">Live Matrix Plus</Link>
                  </li>
                  <li>
                    <Link to="/tools/warrantsearch">Warrant search</Link>
                  </li>
                  <li>
                    <Link to="/tools/warrantterms">Warrant terms</Link>
                  </li>
                  <li>
                    <Link to="/tools/warrantcalculator">Warrant calculator</Link>
                  </li>
                  <li>
                    <Link to="/tools/underlying">Underlying charts</Link>
                  </li>
                  <li>
                    <Link to="/tools/warrantcomparison">Warrant comparison</Link>
                  </li>
                  <li>
                    <Link to="/tools/document">Warrant documents</Link>
                  </li>
                  <li>
                    <Link to="/tools/heatmap">Warrant heatmap</Link>
                  </li>
                  {/* <li>
                    <Link to="/tools/recognia">Technical Event® Lookup</Link>
                  </li> */}
                </div>
              </div>
            </ul>
          </div>




          <div className="sitemap-col">
            <ul>
              <div style={{
                margin: 0, padding: 0, borderBottom: "none", fontSize: 14, color: "#fff", paddingBottom:
                  4, fontFamily: '"MCQGlobal_W_Md"'
              }}>
                <div data-bs-toggle="collapse" data-bs-target="#mblmenu2" style={{ cursor: "pointer" }}>
                  <li className="d-flex justify-content-between align-items-center color-primary first_li">
                    <i className="fa fa-angle-down float-right"></i>
                    Warrant statistics

                  </li>
                </div>
                <div id="mblmenu2" className="navmbl-panel-collapse-mdlg collapse" style={{ margin: 0, fontWeight: 400, borderTop: "1px solid rgb(255, 255, 255, 0.5)" }}>
                  <li>
                    <Link to="/marketdata/toptradedstocks">Top traded stocks</Link>
                  </li>
                  <li>
                    <Link to="/marketdata/toptradedwarrants">Top traded warrants</Link>
                  </li>
                  <li>
                    <Link to="/marketdata/turnoverdist">Turnover distribution</Link>
                  </li>
                  <li>
                    <Link to="/marketdata/turnover">Warrant market turnover</Link>
                  </li>
                  <li>
                    <Link to="/marketdata/moneyflow">Money flow</Link>
                  </li>
                </div>
              </div>
            </ul>
          </div>



          <div className="sitemap-col warrent_edu_li">
            <ul>
              <div style={{
                margin: 0, padding: 0, borderBottom: "none", fontSize: 14, color: "#fff", paddingBottom:
                  4, fontFamily: '"MCQGlobal_W_Md"'
              }}>
                <div data-bs-toggle="collapse" data-bs-target="#mblmenu3" style={{ cursor: "pointer" }}>
                  <li className="d-flex justify-content-between align-items-center color-primary first_li">
                    <i className="fa fa-angle-down float-right"></i>
                    Warrant education

                  </li>
                </div>
                <div id="mblmenu3" className="navmbl-panel-collapse-mdlg collapse" style={{ margin: 0, fontWeight: 400, borderTop: "1px solid rgb(255, 255, 255, 0.5)" }}>
                  <li className='inner_first_li' id="new_to_warrent">

                    {/* <i className="fa fa-angle-down float-right"></i> */}

                    <div className="sitemap-col">
                      <ul>
                        <div style={{
                          margin: 0, padding: 0, borderBottom: "none", fontSize: 14, color: "#fff", paddingBottom:
                            4, fontFamily: '"MCQGlobal_W_Md"'
                        }}>
                          <div data-bs-toggle="collapse" data-bs-target="#mblmenu31" style={{ cursor: "pointer" }}>
                            <li className="d-flex justify-content-between align-items-center second_li">

                              New to warrants
                              <i className="fa fa-angle-down float-right"></i>
                            </li>
                          </div>
                          <div id="mblmenu31" className="navmbl-panel-collapse-mdlg collapse" style={{ margin: 0, fontWeight: 400, borderTop: "1px solid rgb(255, 255, 255)",
                        borderBottom: "1px solid rgb(255, 255, 255)" }}>
                            <li>
                              <Link to="/education/getstarted">How to get started</Link>
                            </li>
                            <li>
                              <Link to="/FirstStep">Your first step</Link>
                            </li>
                            <li>
                              <Link to="/education/warrantvideos">Educational videos</Link>
                            </li>
                            <li>
                              <Link to="/SuccessSpeaks">Success Speaks</Link>
                            </li>
                          </div>
                        </div>
                      </ul>
                    </div>
                  </li>
























                  <li className='inner_first_li'>
                    <div className="sitemap-col">
                      <ul>
                        <div style={{
                          margin: 0, padding: 0, borderBottom: "none", fontSize: 14, color: "#fff", paddingBottom:
                            4, fontFamily: '"MCQGlobal_W_Md"'
                        }}>
                          <div data-bs-toggle="collapse" data-bs-target="#mblmenu32" style={{ cursor: "pointer" }}>
                            <li className="d-flex justify-content-between align-items-center second_li">
                              Existing warrant investors
                              <i className="fa fa-angle-down float-right"></i>
                            </li>
                          </div>
                          <div id="mblmenu32" className="navmbl-panel-collapse-mdlg collapse" style={{ margin: 0, fontWeight: 400, borderTop: "1px solid rgb(255, 255, 255)", borderBottom: "1px solid rgb(255, 255, 255)" }}>
                            <li>
                              <Link to="/education/warrantvideos/#GuestSpeakerVideo">
                                Guest speaker videos
                              </Link>
                            </li>
                            <li>
                              <Link to="/education/indexwarrants">
                                Index warrants
                              </Link>
                            </li>
                            <li>
                              <Link to="/education/whymacquarie">
                                Why Macquarie?
                              </Link>
                            </li>
                            <li>
                              <Link to="/education/warrantvideosind/y7Zkk7yuoDs/6">
                                How to use the Live Matrix
                              </Link>
                            </li>
                          </div>
                        </div>
                      </ul>
                    </div>
                  </li>

















                  <li>
                    <Link to="/edu">
                      Investor academy
                    </Link>
                  </li>
                  <li>
                    <Link to="/education/faq">
                      Frequently asked questions
                    </Link>
                  </li>
                  <li>
                    <Link to="/education/seminar">Seminars</Link>
                  </li>
                </div>
              </div>
            </ul>
          </div>




          <div className="sitemap-col clearfix">
            <ul>
              <div style={{
                margin: 0, padding: 0, borderBottom: "none", fontSize: 14, color: "#fff", paddingBottom:
                  4, fontFamily: '"MCQGlobal_W_Md"'
              }}>
                <div data-bs-toggle="collapse" data-bs-target="#mblmenu4" style={{ cursor: "pointer" }}>
                  <li className="d-flex justify-content-between align-items-center color-primary first_li">
                    <i className="fa fa-angle-down float-right"></i>
                    Market news

                  </li>
                </div>
                <div id="mblmenu4" className="navmbl-panel-collapse-mdlg collapse" style={{ margin: 0, fontWeight: 400, border: "none" }}>
                  <li>
                    <Link to="/telegram">Join us on Telegram</Link>
                  </li>
                  <li>
                    <Link to="/marketnews/dailyhighlights">Daily highlights</Link>
                  </li>
                  <li>
                    <Link to="/marketnews/eodinsights">End of day insights</Link>
                  </li>
                  <li>
                    <Link to="/marketnews/overnightmarkets">Overnight market wrap</Link>
                  </li>
                  <li>
                    <Link to="/marketnews/subscribe">Subscribe</Link>
                  </li>
                  <li>
                    <Link to="/marketnews/activities">Activities</Link>
                  </li>
                  <li>
                    <Link to="/marketnews/photogallery">Photo gallery</Link>
                  </li>
                </div>
              </div>
            </ul>
          </div>
        </div>







        <div style={{ clear: "both" }} />
        
        <hr className="hidden-xs hidden-sm liner" />
        <div className="row policies_desk">
          <div className="sitemap-othr-xs d-none">
            <div className="sitemap-othr-col">
              <div>
                <Link to="/about/important-information">Important information </Link>
              </div>
              <div>
                <Link to="/about/privacy-policy"> Privacy policy </Link>
              </div>
              <div>
                <Link to="/about/terms-of-use">  Terms of use </Link>
              </div>
            </div>
            <div className="sitemap-othr-col last">
              <div>
                <Link to="/about/disclaimer"> Disclaimer</Link>
              </div>
            </div>
            <div className="clearfix" />
          </div>
          <div className="col-sm-12 sitemap-othr-sm d-none">
            <p>
              <Link to="/about/important-information">Important information </Link> |

              <Link to="/about/privacy-policy"> Privacy policy </Link> |

              <Link to="/about/terms-of-use">  Terms of use </Link> |

              
              <Link to="/about/disclaimer"> Disclaimer</Link>
            </p>
          </div>
          <div className="col-md-6 sitemap-corp">
            <p>
              © Macquarie Group Limited ABN 94 122 169 279. All rights reserved
            </p>
          </div>
          <div className="col-md-6 sitemap-othr hidden-xs hidden-sm">
            <p>
              <Link to="/about/important-information">Important information </Link> |
              <Link to="/about/privacy-policy"> Privacy policy </Link> |
              <Link to="/about/terms-of-use">  Terms of use </Link> |
              <Link to="/about/disclaimer"> Disclaimer</Link>
            </p>
          </div>
        </div>


        <div className="policies_mob">
          <div className='policies'>
            <p><Link to="/about/important-information">Important information </Link></p>

            <p><Link to="/about/privacy-policy"> Privacy policy </Link></p>

            <p><Link to="/about/terms-of-use">  Terms of use </Link></p>
          </div>
          <div className='disclaimer'>
            <p><Link to="/about/disclaimer"> Disclaimer</Link></p>
          </div>
          <div className='all_rights'>
            <p>© Macquarie Group Limited ABN 94 122 169 279. All rights reserved</p>
          </div>

        </div>





      </div>
      {/* <div className='container-fluid'>
        
      </div> */}
    </div>
  );
};

export default Footer;
